<template>
  <div class="box" v-show="isDisPlay">
    <div class="bgImg">
      <img src="../static/off.png" class="offImg" @click="offbox" />
      <div class="content">
        <img
          class="img"
          :src="
            detail.img
              ? detail.img.replace(
                  'https://123.56.108.165:9000',
                  'https://gyserver.smartyunzhou.com:9000'
                )
              : ''
          "
          alt=""
        />
        <div class="txt">
          <div class="titleBox">
            <span class="title">随手拍事件</span>
            <img class="imgright" src="../static/Union.png" alt="" />
          </div>
          <div class="keyvalue">
            <div
              class="line"
              v-for="(item, index) in Object.keys(displayData)"
              :key="index"
            >
              <span class="key">{{ item }}:</span>
              <span class="value">{{ displayData[item] }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import {  defineComponent,onMounted,ref,reactive } from "vue";
import {  receiveMessage   } from "@/eventProcessing";
// import { useRouter } from 'vue-router'

// import { ElSlider } from "element-plus";

export default defineComponent({
  name: "event",
  components:{  },
  setup() {
    //  const style={ }
    // const router = useRouter();
    const detail=ref({});
    const displayData=reactive({});
    const isDisPlay=ref(false);
    const keyToName={address:'标题',message:'事件内容',realname:'事件处理人员',state:{name:'事件处理状态',enum:['未处理','处理中','已完成','未完成']},result:'事件处理结果'};
    const refresh=(data)=>{
      for(let key in keyToName){
        const nameJs=keyToName[key];
        if(typeof nameJs==='string'){
          displayData[nameJs]=data[key]
        }else{
          displayData[nameJs.name]=nameJs.enum[data[key]]
        }
      }
    }
    onMounted(() => {
      // 打印
      // detail.value=JSON.parse(decodeURIComponent(router.currentRoute.value.query.one))
      // detail.value=JSON.parse(props.one)
      // detail.value={}
      // refresh(detail.value)
      // console.log(displayData)
      //console.log('router:', router.currentRoute.value.query)
    })
    receiveMessage('clickSspPoi',(one)=>{
      detail.value=one
      refresh(detail.value)
      isDisPlay.value=true
    })
    const offbox=()=>{
      isDisPlay.value=false
    }
      return { displayData,detail,isDisPlay,offbox};
    },
});
</script>

<style scope>
.box {
  /* overflow: hidden; */
  /* transform: scale(3.4285714285714284, 4.153846153846154);
  transform-origin: 0px 0px; */
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.offImg {
  position: absolute;
  display: block;
  right: 20px;
  top: 15px;
  width: 25px;
  height: 25px;
}
.offImg:hover {
  background: rgb(39, 255, 170, 0.7);
  border-radius: 50%;
}
.bgImg {
  float: left;
  /* position: absolute; */
  /* background-image: url(../static/bg2.png); */
  background: rgb(31, 54, 58, 0.7);
  border: rgb(39, 255, 170, 0.7) 2px solid;
  /* min-height: 214px;
  min-width: 248px; */
  min-width: 370.5px;
  /* max-width: 400px; */
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* transform: translate(-50%, -100%); */
  padding: 18.5px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
/* .jian {
  display: block;
  float: left;
  width: 125.5px;
  height: 110.5px;
  position: relative;
  top: 150px;
} */
.titleBox {
  /* margin-top: 2px; */
  position: relative;
  overflow: hidden;
}
.title {
  overflow: hidden;
  float: left;
  font-family: "江城斜黑体";
  font-size: 23px;
  line-height: 23px;
  /* identical to box height, or 100% */
  letter-spacing: 2px;
  background: linear-gradient(
    90deg,
    #ffffff -16.41%,
    rgba(45, 255, 172, 0.69) 124.22%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.imgright {
  overflow: hidden;
  float: left;
  width: 25px;
  height: 15px;
  margin-left: 7px;
  /* margin-top: 24px; */
  /* position: absolute;
  top: 50%;
  transform: translateY(-50%); */
}
.content {
  /* padding: 20px 22px 42px 22px; */
  /* or 117% */
  /* background-color: #3227ff; */
  overflow: hidden;
}
.content .img {
  display: block;
  float: left;
  width: 160px;
  /* height: 160px; */
  overflow: hidden;
}
.txt {
  display: block;
  float: left;
  margin-left: 22px;
  overflow: hidden;
}
.keyvalue {
  margin-top: 20px;
  font-family: "Alibaba PuHuiTi 2.0";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: 2px;
  width: 250px;
}
.keyvalue .line {
  display: block;
  margin-top: 15px;
  overflow: hidden;
}
.keyvalue .line:first-child {
  margin-top: 0px;
}
.keyvalue .line .key {
  /* float: left; */
  color: #ffffff;
}

.keyvalue .line .value {
  /* float: left; */
  color: #27ffaa;
}

.jian {
}
</style>
