export const sendMessage = (tag, msg) => {
  if (tag == "message") throw new Error("不能发送tag为message的消息");
  const event = new CustomEvent(tag, { detail: msg });
  document.dispatchEvent(event);
  const iframes = document.getElementsByTagName("iframe");

  if (iframes) {
    for (let iframe of iframes) {
      iframe.contentWindow.postMessage({ tag, detail: msg }, "*");
    }
  }
  window.parent.postMessage({ tag, detail: msg }, "*");
  //document.getElementById("zhyzcloudrender").contentWindow.postMessage("sendMessage", '1232312');
};

export const receiveMessage = (tag, callback) => {
  document.addEventListener(tag, function (e) {
    callback(e.detail);
  });
  window.addEventListener("message", function (e) {
    // callback(e.detail)
    // {}
    if (e.data.tag == tag) {
      callback(e.data.detail);
    }
  });
};
